<template>
	<ValidationForm
		id="manage_cookie_banner_container"
		#default="{ handleSubmit, dirty }"
		ref="validationForm"
		class="cassie-vertical-md"
		style="width: 100%; overflow-y: auto"
		tag="div"
		@form-changed="onConfigurationFormChanged"
	>
		<CookieTemplateLayout
			v-if="showManageCookieBannerScreen"
			:header-title="'Configure Cookie Banner' | useLabels('Configure Cookie Banner')"
			:header-caption="'Configure a cookie banner' | useLabels('Configure a cookie banner')"
		>
			<template #header-after>
				<Stepper
					:step.sync="currentStep"
					:steps="steps"
					:in-progress="!isEdit || (isEdit && configurationFormChanged && currentStep === 0)"
				/>
			</template>
			<template #content>
				<StepperItems
					:step="currentStep"
					:steps="steps"
				>
					<template #0>
						<CookieBannerConfigurationStep
							:cookie-banner.sync="cookieBanner"
							:cookie-appearance-templates="cookieAppearanceTemplates"
							:cookie-collection-templates="cookieCollectionTemplates"
							:cookie-text-templates="cookieTextTemplates"
							:is-edit="isEdit"
							:clone-mode="cloneMode"
							:cdc-cookie-collection-selected="cdcCookieCollectionSelected"
							@show-cookie-text-manage-screen="showManageTextTemplateScreen = true"
							@open-edit-text-template-screen="onOpenEditTextTemplateScreen"
							@show-cookie-collection-manage-screen="showManageCollectionTemplateScreen = true"
							@open-edit-collection-template-screen="onOpenEditCollectionTemplateScreen"
							@show-cookie-appearance-manage-screen="showManageAppearanceTemplateScreen = true"
							@open-edit-appearance-template-screen="onOpenEditAppearanceTemplateScreen"
							@submit="submit"
							@back-to-overview="backToOverview"
							@next="navigateToEmbedCode"
							@configuration-form-changed="onConfigurationFormChanged"
						/>
					</template>
					<template #1>
						<CookieBannerInstallationStep
							:assigned-languages="languages"
							:cookie-banner-id="cookieBannerId"
							:access-key="selectedAccessKey"
							:allowed-domains="bannerDomains"
							:cdc-cookie-collection-selected="cdcCookieCollectionSelected"
							@back-to-overview="backToOverview"
							@previous="navigateToConfiguration"
						/>
					</template>
				</StepperItems>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton @click="backToOverview">
							Back to overview
						</SecondaryActionButton>
						<v-spacer />
						<template v-if="currentStep === 0">
							<PrimaryActionButton
								v-if="userCanCreateUpdate"
								:disabled="!dirty"
								@click="handleSubmit(submit)"
							>
								{{ isEdit ? 'Save' : 'Create' }}
							</PrimaryActionButton>
							<PrimaryActionButton
								v-if="isEdit"
								:disabled="dirty"
								class="ml-2"
								@click="navigateToEmbedCode"
							>
								Next
							</PrimaryActionButton>
						</template>
						<template v-else>
							<PrimaryActionButton
								@click="navigateToConfiguration"
							>
								Previous
							</PrimaryActionButton>
						</template>
					</template>
				</PageActionRow>
			</template>
		</CookieTemplateLayout>
		<ManageCookieTextTemplate
			v-if="showManageTextTemplateScreen"
			:cookie-text-template-to-edit="cookieTextTemplateToEdit"
			accessed-via="manage-cookie-banner"
			@submit="submitCookieTextTemplate"
			@get-new-text-template-id="addNewTextTemplateToBanner"
			@close="closeManageCookieTextTemplateScreen"
		/>
		<ManageCookieCollectionTemplate
			v-if="showManageCollectionTemplateScreen"
			:cookie-collection-template-to-edit="cookieCollectionTemplateToEdit"
			accessed-via="manage-cookie-banner"
			@submit="submitCookieCollectionTemplate"
			@get-new-collection-template-id="addNewCollectionTemplateToBanner"
			@close="closeManageCookieCollectionTemplateScreen"
		/>
		<ManageCookieAppearanceTemplate
			v-if="showManageAppearanceTemplateScreen"
			:cookie-appearance-template-to-edit="cookieAppearanceTemplateToEdit"
			accessed-via="manage-cookie-banner"
			@submit="submitCookieAppearanceTemplate"
			@get-new-appearance-template-id="addNewAppearanceTemplateToBanner"
			@close="closeManageCookieAppearanceTemplateScreen"
		/>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import Stepper from '../../../../../../../shared/components/stepper.vue'
import StepperItems from '../../../../../../../shared/components/stepper-items.vue'
import CookieBannerConfigurationStep from './cookie-banner-configuration-step.vue'
import CookieBannerInstallationStep from './cookie-banner-installation-step.vue'
import ManageCookieTextTemplate from '../cookie-text-templates/_manage-cookie-text-template.vue'
import ManageCookieAppearanceTemplate from '../cookie-appearances-templates/_manage-cookie-appearance-template.vue'
import ManageCookieCollectionTemplate from '../cookie-collection-templates/_manage-cookie-collection-template.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import { COOKIE_BANNERS } from '../../../../../router/route-names.js'
import { getAssignedLanguages } from '../../../../../../../shared/utils/api/languages.js'
import { postCookieBanner, putCookieBanner } from '../../../../../../../shared/utils/api/cookies/cookie-banners.js'
import { getCookieAppearanceTemplates } from '../../../../../../../shared/utils/api/cookies/cookie-appearance-templates.js'
import { getCookieTextTemplates } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { getCookieCollectionTemplates } from '../../../../../../../shared/utils/api/cookies/cookie-collection-templates.js'
import { CAN_CREATE_UPDATE_COOKIE_BANNERS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { getConsentBannerAccessKeys } from '../../../../../../../shared/utils/api/access-keys.js'
import { defaultBrandInSelect } from '../../../../../../../shared/state/brands.js'
import {
	COLOUR_SCHEME_IDENTIFIERS,
	PRE_BANNER_LIGHT_COLOUR_SCHEME,
	GPC_BANNER_LIGHT_COLOUR_SCHEME,
	BANNER_LIGHT_COLOUR_SCHEME
} from '../cookie-appearances-templates//appearance-enums.js'
export default {
	components: {
		ValidationForm,
		CookieTemplateLayout,
		Stepper,
		StepperItems,
		CookieBannerConfigurationStep,
		CookieBannerInstallationStep,
		ManageCookieTextTemplate,
		ManageCookieCollectionTemplate,
		ManageCookieAppearanceTemplate,
		PrimaryActionButton,
		SecondaryActionButton,
		PageActionRow
	},
	props: {
		cookieBannerToEdit: Object,
		cloneMode: {
			type: Boolean,
			default: false
		}
	},
	setup () {
		return { defaultBrandInSelect }
	},
	data () {
		return {
			currentStep: this.initialStep || 0,
			steps: [
				{ slot: 0, title: 'Cookie Banner Configuration', stepNumber: '01', stepComplete: false },
				{ slot: 1, title: 'Embed Code', stepNumber: '02', stepComplete: false }
			],
			cookieBanner: JSON.parse(JSON.stringify(this.cookieBannerToEdit || {
				name: '',
				description: '',
				brandId: this.defaultBrandInSelect,
				enableCis: false, // remove value before calling API
				identityServiceEndpoint: null,
				cisCookies: [],
				privacyPolicyId: null,
				cookieTextTemplateId: null,
				cookieAppearanceTemplateId: null,
				cookieCollectionTemplateId: null,
				accessKeyId: null,
				allowedDomainsValue: true, // remove value before calling API
				allowedDomains: [],
				cassieCookiesExpiryInDays: 365
			})),
			languages: [],
			cookieCollectionTemplates: [],
			cookieTextTemplates: [],
			cookieAppearanceTemplates: [],
			showManageCollectionTemplateScreen: false,
			cookieCollectionTemplateToEdit: null,
			showManageTextTemplateScreen: false,
			cookieTextTemplateToEdit: null,
			showManageAppearanceTemplateScreen: false,
			cookieAppearanceTemplateToEdit: null,
			cookieBannerId: null,
			cookieAccessKeys: [],
			configurationFormChanged: false
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_BANNERS)
		},
		isEdit () {
			return (!!this.cookieBannerToEdit || !!this.cookieBannerId) && !this.cloneMode
		},
		initialStep () {
			return this.isEdit ? 1 : 0
		},
		showManageCookieBannerScreen () {
			return !this.showManageCollectionTemplateScreen && !this.showManageTextTemplateScreen && !this.showManageAppearanceTemplateScreen
		},
		selectedAccessKey () {
			const selectedAccessKey = this.cookieAccessKeys.find(accessKey => accessKey.accessKeyId === this.cookieBanner.accessKeyId)?.accessKey
			return selectedAccessKey || ''
		},
		cdcCookieCollectionSelected () {
			return this.cookieCollectionTemplates?.find(({ id }) => id === this.cookieBanner.cookieCollectionTemplateId)?.crossDomainConsent
		},
		bannerDomains () {
			if (Array.isArray(this.cookieBanner.allowedDomains)) {
				return this.cookieBanner.allowedDomains?.map(domain => ({
					text: domain,
					value: domain
				}))
			} else {
				return this.cookieBanner.allowedDomains?.split(',').map(domain => ({
					text: domain,
					value: domain
				}))
			}
		}
	},
	async created () {
		await Promise.all([
			this.getCookieCollectionTemplates(),
			this.getCookieTextTemplates(),
			this.getCookieAppearanceTemplates(),
			this.getLanguages()
		])
		if (this.isEdit) {
			this.cookieBannerId = this.cookieBannerToEdit.cookieBannerId
		}
		const { cookieAccessKeys } = await getConsentBannerAccessKeys()
		this.cookieAccessKeys = cookieAccessKeys
		if (this.cloneMode) {
			this.$refs.validationForm.$refs.validationObserver.flags.dirty = true
		}
	},
	methods: {
		async getCookieCollectionTemplates () {
			const { cookieCollectionTemplates } = await getCookieCollectionTemplates()
			this.cookieCollectionTemplates = cookieCollectionTemplates
		},
		async getCookieTextTemplates () {
			const { cookieTextTemplates } = await getCookieTextTemplates()
			this.cookieTextTemplates = cookieTextTemplates
		},
		async getCookieAppearanceTemplates () {
			const { cookieAppearanceTemplates } = await getCookieAppearanceTemplates()
			this.cookieAppearanceTemplates = cookieAppearanceTemplates
			if (this.cookieAppearanceTemplates && this.cookieAppearanceTemplates.length > 0) {
				this.cookieAppearanceTemplates.forEach(cookieAppearanceTemplate => {
					if (!cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON) {
						cookieAppearanceTemplate.preBanner.preBannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
						cookieAppearanceTemplate.preBanner.preBannerColourSchemeJSON = PRE_BANNER_LIGHT_COLOUR_SCHEME
					}
					if (!cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON) {
						cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
						cookieAppearanceTemplate.gpcBanner.gpcBannerColourSchemeJSON = GPC_BANNER_LIGHT_COLOUR_SCHEME
					}
					if (!cookieAppearanceTemplate.banner.bannerColourSchemeJSON) {
						cookieAppearanceTemplate.banner.bannerColourSchemeIdentifier = COLOUR_SCHEME_IDENTIFIERS.NONE
						cookieAppearanceTemplate.banner.bannerColourSchemeJSON = BANNER_LIGHT_COLOUR_SCHEME
					}
				})
			}
		},
		backToOverview () {
			this.$router.push({ name: COOKIE_BANNERS })
		},
		navigateForward () {
			this.steps[this.currentStep].stepComplete = true
			this.currentStep++
		},
		navigateToEmbedCode () {
			this.currentStep = 1
		},
		// text template events
		closeManageCookieTextTemplateScreen () {
			this.showManageTextTemplateScreen = false
			this.cookieTextTemplateToEdit = null
		},
		submitCookieTextTemplate () {
			this.closeManageCookieTextTemplateScreen()
			this.getCookieTextTemplates()
		},
		onOpenEditTextTemplateScreen (selectedTextTemplateId) {
			this.showManageTextTemplateScreen = true
			this.cookieTextTemplateToEdit = this.cookieTextTemplates.find(template => template.id === selectedTextTemplateId)
		},
		addNewTextTemplateToBanner (newTextTemplateId) {
			this.cookieBanner.cookieTextTemplateId = newTextTemplateId
		},
		// collection template events
		closeManageCookieCollectionTemplateScreen () {
			this.showManageCollectionTemplateScreen = false
			this.cookieCollectionTemplateToEdit = null
		},
		submitCookieCollectionTemplate () {
			this.closeManageCookieCollectionTemplateScreen()
			this.getCookieCollectionTemplates()
		},
		onOpenEditCollectionTemplateScreen (selectedCollectionTemplateId) {
			this.showManageCollectionTemplateScreen = true
			this.cookieCollectionTemplateToEdit = this.cookieCollectionTemplates.find(template => template.id === selectedCollectionTemplateId)
		},
		addNewCollectionTemplateToBanner (newCollectionTemplateId) {
			this.cookieBanner.cookieCollectionTemplateId = newCollectionTemplateId
		},
		// appearance template events
		closeManageCookieAppearanceTemplateScreen () {
			this.showManageAppearanceTemplateScreen = false
			this.cookieAppearanceTemplateToEdit = null
		},
		submitCookieAppearanceTemplate () {
			this.closeManageCookieAppearanceTemplateScreen()
			this.getCookieAppearanceTemplates()
		},
		onOpenEditAppearanceTemplateScreen (selectedAppearanceTemplateId) {
			this.showManageAppearanceTemplateScreen = true
			this.cookieAppearanceTemplateToEdit = this.cookieAppearanceTemplates.find(template => template.id === selectedAppearanceTemplateId)
		},
		addNewAppearanceTemplateToBanner (newAppearanceTemplateId) {
			this.cookieBanner.cookieAppearanceTemplateId = newAppearanceTemplateId
		},
		async submit () {
			let banner = JSON.parse(JSON.stringify(this.cookieBanner))
			banner.identityServiceEnabled = banner.enableCis
			banner.identityServiceEndpoint = !this.cdcCookieCollectionSelected ? banner.identityServiceEndpoint : null
			if (!banner.allowedDomainsValue) {
				banner.allowedDomains = banner.allowedDomains.join(',')
			} else {
				banner.allowedDomains = ''
			}
			delete banner.allowedDomainsValue
			banner.description = banner.name
			if (!this.isEdit) {
				await postCookieBanner(banner).then(response => {
					this.cookieBannerId = response.data.newCookieBannerId
				})
			} else {
				if (!banner.cookieBannerId) banner = { ...banner, cookieBannerId: this.cookieBannerId }
				await putCookieBanner(banner)
			}
			this.navigateToEmbedCode()
		},
		async getLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.languages = assignedLanguages
		},
		navigateToConfiguration () {
			this.currentStep = 0
		},
		onConfigurationFormChanged (changed) {
			this.configurationFormChanged = changed
		}
	}
}
</script>

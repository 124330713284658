<template>
	<SectionCard>
		<template #title>
			Cassie Identity Service (CIS)
		</template>
		<template #subtitle>
			Cassie Identity Service allows a Cookie Banner to
			recreate and re add cookies back into in the user's
			browser that they have opted into previously but have
			since been deleted from their browser (e.g. by ITP in
			Safari or ETP in Firefox).
		</template>
		<template #title-action>
			<Toggle
				:value="cisEnabled"
				label-left
				label="Enabled"
				:disabled="cdcCookieCollectionSelected || !userCanCreateUpdate"
				@update:value="updateBanner('enableCis', $event)"
			/>
		</template>

		<template
			v-if="cisEnabled"
			#body
		>
			<v-row dense>
				<div class="d-flex align-center mr-2">
					{{ cisEnvironment }}
				</div>
				<v-col cols="3">
					<TextField
						v-if="!cdcCookieCollectionSelected"
						:value.sync="cookieBanner.identityServiceEndpoint"
						label="Domain *"
						tooltip-text="Please enter the domain that this Cookie Banner will be used on e.g. syrenis.com"
						:rules="{required: true, isLink: true}"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('identityServiceEndpoint', $event)"
					/>
					<Dropdown
						v-else
						:value.sync="cookieBanner.identityServiceEndpoint"
						:items="mappedAllowedDomains"
						label="Domain *"
						:disabled="!userCanCreateUpdate"
						@input="updateBanner('identityServiceEndpoint', $event)"
					/>
				</v-col>
				<v-col cols="3">
					<PrimaryActionButton
						@click="testConnection"
					>
						Test Connection
					</PrimaryActionButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					Please select which cookies you wish to have protected by
					Cassie Identity Service (CIS)
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="4">
					<Dropdown
						v-model="selectedCookieScript"
						:items="cookieScriptItems"
						label="Cookie Script"
						:disabled="!userCanCreateUpdate"
					/>
				</v-col>
				<v-col cols="4">
					<Dropdown
						v-model="selectedCookieName"
						:items="cookieNames"
						label="Cookie Name"
						:disabled="!userCanCreateUpdate"
					/>
				</v-col>
				<v-col cols="4">
					<PrimaryActionButton
						:disabled="(!selectedCookieName && !selectedCookieScript) || !userCanCreateUpdate"
						@click="addCisCookie"
					>
						Add
					</PrimaryActionButton>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="12">
					<DataTable
						:items="cookieBanner.cisCookies"
						:headers="cisCookieHeaders"
					>
						<template #item.cookieScriptName="{ item }">
							{{ cookieScriptName(item.cookieScriptId) }}
						</template>
						<template #item.cookieScriptType="{ item }">
							{{ cookieScriptType(item.cookieScriptId) }}
						</template>
						<template #item.action="{ index }">
							<IconButton
								v-if="userCanCreateUpdate"
								@click="removeCisCookie(index)"
							>
								mdi-trash-can
							</IconButton>
						</template>
					</DataTable>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>
<script>
import axios from 'axios'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import { getEnv } from '../../../../../../../shared/utils/runtime-settings.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		SectionCard,
		TextField,
		Toggle,
		Dropdown,
		PrimaryActionButton,
		IconButton,
		DataTable
	},
	props: {
		cookieBanner: Object,
		cookieBannerScripts: Array,
		updateBanner: Function,
		cdcCookieCollectionSelected: Boolean,
		allowedDomains: [Array, String],
		userCanCreateUpdate: Boolean
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			selectedCookieScript: null,
			selectedCookieName: null
		}
	},
	computed: {
		cisEnabled () {
			if (this.cdcCookieCollectionSelected) {
				return true
			} else return this.cookieBanner.enableCis
		},
		cisEnvironment () {
			return getEnv('VUE_APP_CASSIE_IDENTITY_SERVICE_URL')
		},
		cookieScriptItems () {
			return this.cookieBannerScripts.map(({ cookieScriptName, id }) => ({
				text: cookieScriptName,
				value: id
			})).sort((a, b) => a.text?.localeCompare(b.text))
		},
		cookieNames () {
			const cookieNames = this.cookieBannerScripts.find(({ id }) => id === this.selectedCookieScript)?.namesOfCookiesInScript
			if (cookieNames) {
				const bannerCisCookies = this.cookieBanner.cisCookies.map(cisCookie => {
					return cisCookie.cookieName
				})
				return cookieNames.split(',').filter(cookieName => !bannerCisCookies.includes(cookieName)).map(cookieName => ({
					text: cookieName,
					value: cookieName
				}))
			} else return []
		},
		cisCookieHeaders () {
			return [
				{
					text: 'Cookie Script Name',
					value: 'cookieScriptName'
				},
				{
					text: 'Cookie Name in Browser',
					value: 'cookieName'
				},
				{
					text: 'Type',
					value: 'cookieScriptType'
				},
				{
					text: 'Action',
					value: 'action'
				}
			]
		},
		mappedAllowedDomains () {
			return this.allowedDomains.map(domain => ({
				text: domain,
				value: domain
			}))
		}
	},
	methods: {
		async testConnection () {
			const idsUrl = this.cisEnvironment + this.cookieBanner.identityServiceEndpoint + '/health'
			const api = axios.create()
			api.get(idsUrl, { timeout: 5 * 1000 })
				.then(_ => {
					this.showSnackbar('Connection Test Success: ' + this.cisEnvironment + this.cookieBanner.identityServiceEndpoint)
				})
				.catch(_ => {
					this.showSnackbar({ text: 'Please check your CIS settings and try again.', color: 'red' })
				})
		},
		addCisCookie () {
			this.updateBanner('cisCookies', [
				...this.cookieBanner.cisCookies,
				{
					cookieScriptId: this.selectedCookieScript,
					cookieName: this.selectedCookieName
				}
			])
			this.selectedCookieScript = null
			this.selectedCookieName = null
		},
		removeCisCookie (index) {
			const cisCookies = this.cookieBanner.cisCookies
			this.$delete(cisCookies, index)
			this.updateBanner('cisCookies', [
				...cisCookies
			])
		},
		cookieScriptType (cookieScriptId) {
			return this.cookieBannerScripts.find(({ id }) => id === cookieScriptId)?.cookieScriptType
		},
		cookieScriptName (cookieScriptId) {
			return this.cookieBannerScripts.find(({ id }) => id === cookieScriptId)?.cookieScriptName
		}
	}
}
</script>

<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Access Key
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<!-- add permisions -->
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="accessKey.name"
								label="Access Key Name *"
								rules="required"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="accessKey.brandId"
								:disabled="isEdit || !clientMultiBranded || singleBrand"
								:items="brandItems"
								:label="'Brand *' | useLabels"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<Textarea
								v-model="accessKey.description"
								label="Description"
								no-resize
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Textarea from '../../../../../../../shared/components/textarea.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import { postCookieAccessKey, putCookieAccessKey } from '../../../../../../../shared/utils/api/access-keys.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { defaultBrandInSelect, singleBrand, clientMultiBranded } from '../../../../../../../shared/state/brands.js'

export default {
	components: {
		Modal,
		ValidationForm,
		TextField,
		Textarea,
		Dropdown,
		SecondaryActionButton,
		PrimaryActionButton,
		IconButton
	},
	props: {
		brandItems: Array,
		accessKeyToEdit: Object
	},
	setup () {
		return {
			showSnackbar,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			accessKey: JSON.parse(JSON.stringify(this.accessKeyToEdit || {
				name: '',
				description: '',
				brandId: defaultBrandInSelect.value
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.accessKeyToEdit
		}
	},
	methods: {
		async submit () {
			if (!this.isEdit) {
				await postCookieAccessKey(this.accessKey).then(response => {
					this.$emit('submit')
					this.$emit('get-new-access-key-id', response.data.newAccessKeyId)
				})
				showSnackbar('Cookie Access Key created successfully.')
			} else {
				delete this.accessKey.accessKeyId
				delete this.accessKey.accessKeyName
				delete this.accessKey.brandName
				delete this.accessKey.createdDate
				delete this.accessKey.isConsentBannerDefault

				await putCookieAccessKey(this.accessKey)
				this.$emit('submit')
				showSnackbar('Cookie Access Key updated successfully.')
			}
			this.$emit('load-access-keys')
		}
	}
}
</script>

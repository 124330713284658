<template>
	<SectionCard embedded>
		<template #body>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<div class="cassie-vertical-md">
						<div class="ml-5">
							These events are supported to allow for capturing data from the cookie widget.
						</div>
						<v-expansion-panels accordion>
							<v-expansion-panel
								v-for="event in EVENTS"
								:key="event.id"
							>
								<v-expansion-panel-header>
									{{ event.name }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<Prism>
										{{ event.value }}
									</Prism>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</template>
				<template #1>
					<div class="cassie-vertical-md">
						<div class="ml-5">
							These functions are supported to allow for capturing data along with retrieving consents from the cookie widget.
						</div>
						<v-expansion-panels accordion>
							<v-expansion-panel
								v-for="event in CONSENT_MANAGEMENT"
								:key="event.id"
							>
								<v-expansion-panel-header>
									{{ event.name }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<Prism>
										{{ event.value }}
									</Prism>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</template>
				<template #2>
					<div class="cassie-vertical-md">
						<div class="ml-5">
							If you have custom template for our widget you can optionally use our functions to raise an event.
						</div>
						<v-expansion-panels accordion>
							<v-expansion-panel
								v-for="event in CUSTOM_TEMPLATES"
								:key="event.id"
							>
								<v-expansion-panel-header>
									{{ event.name }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<Prism>
										{{ event.value }}
									</Prism>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</template>
				<template #3>
					<div class="cassie-vertical-md">
						<div class="ml-5">
							If you have Global Privacy Control enabled you can optionally use our functions to raise an event.
						</div>
						<v-expansion-panels accordion>
							<v-expansion-panel
								v-for="event in GPC_EVENTS"
								:key="event.id"
							>
								<v-expansion-panel-header>
									{{ event.name }}
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									<Prism>
										{{ event.value }}
									</Prism>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</template>
			</TabItems>
		</template>
	</SectionCard>
</template>
<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Tabs from '../../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../../shared/components/tab-items.vue'
import { EVENTS, CONSENT_MANAGEMENT, CUSTOM_TEMPLATES, GPC_EVENTS } from './cookie-banner-events.js'
export default {
	components: {
		SectionCard,
		Tabs,
		TabItems,
		Prism
	},
	setup () {
		return {
			EVENTS,
			CONSENT_MANAGEMENT,
			CUSTOM_TEMPLATES,
			GPC_EVENTS
		}
	},
	data () {
		return {
			tabs: [{ title: 'Events', slot: '0' }, { title: 'Consent Management', slot: '1' }, { title: 'Custom Templates', slot: '2' }, { title: 'GPC Events', slot: '3' }],
			tab: '0'
		}
	}
}
</script>

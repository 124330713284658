<template>
	<SectionCard>
		<template #title>
			{{ isLegacy ? 'Legacy Embed Code' : 'Embed Code' }}
		</template>
		<template
			v-if="isLegacy"
			#subtitle
		>
			This is a legacy version of the Cookie Banner and the appearance and functionality displayed in the Banner Preview will differ from that displayed by this legacy version
		</template>
		<template #title-action>
			<TextButton @click="copyToClipboard(embedCode)">
				Copy
			</TextButton>
			<TextButton
				v-if="minifiedDynamicEmbedCode != null"
				@click="copyToClipboard(minifiedDynamicEmbedCode)"
			>
				Copy Minified
			</TextButton>
		</template>
		<template #body>
			<div
				style="font-family:'Monaco', monospace"
			>
				<v-card
					width="100%"
					dark
					style="font-family:'Monaco', monospace"
				>
					<Prism>
						{{ embedCode }}
					</Prism>
				</v-card>
			</div>
		</template>
	</SectionCard>
</template>

<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../../../../shared/components/section-card.vue'
import TextButton from '../../../../../../../../shared/components/text-button.vue'
import copyToClipboard from '../../../../../../../../shared/mixins/copy-to-clipboard.js'

export default {
	components: {
		SectionCard,
		Prism,
		TextButton
	},
	mixins: [copyToClipboard],
	props: {
		embedCode: {
			type: String,
			required: true
		},
		isLegacy: {
			type: Boolean,
			required: false
		},
		minifiedDynamicEmbedCode: {
			type: String,
			required: false
		}
	}
}

</script>

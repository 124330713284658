<template>
	<ValidationForm #default="{ handleSubmit }">
		<Modal width="850px">
			<template #modal-title>
				{{ isEdit ? 'Edit' : 'Create' }} Privacy Policy
				<v-spacer />
				<IconButton @click="$emit('close')">
					mdi-close
				</IconButton>
			</template>
			<template #modal-content>
				<!-- add permisions -->
				<div class="cassie-vertical-md">
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="privacyPolicy.privacyPolicyName"
								label="Privacy Policy Name *"
								:rules="{ required:true, max:50 }"
							/>
						</v-col>
						<v-col cols="6">
							<Dropdown
								v-model="privacyPolicy.brandId"
								:disabled="isEdit || !clientMultiBranded || singleBrand"
								:items="brandItems"
								:label="'Brand *' | useLabels"
								rules="required"
							/>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<TextField
								v-model="privacyPolicy.externalVersionRef"
								label="External Version Reference *"
								:rules="{ required:true, max:50 }"
							/>
						</v-col>
						<v-col cols="6">
							<TextField
								v-model="privacyPolicy.privacyPolicyUrl"
								label="Policy URL"
								:rules="{ isUrlwithHttp: true, max: 2000 }"
							/>
						</v-col>
					</v-row>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="$emit('close')">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton
					@click="handleSubmit(submit)"
				>
					{{ !isEdit ? 'Create' : 'Save' }}
				</PrimaryActionButton>
			</template>
		</Modal>
	</ValidationForm>
</template>
<script>
import Modal from '../../../../../../../shared/components/modal.vue'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import { postPrivacyPolicy, putPrivacyPolicy } from '../../../../../../../shared/utils/api/privacy-policies.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { defaultBrandInSelect, singleBrand, clientMultiBranded } from '../../../../../../../shared/state/brands.js'

export default {
	components: {
		Modal,
		ValidationForm,
		TextField,
		Dropdown,
		SecondaryActionButton,
		PrimaryActionButton,
		IconButton
	},
	props: {
		brandItems: Array,
		privacyPolicyToEdit: Object
	},
	setup () {
		return {
			showSnackbar,
			clientMultiBranded,
			defaultBrandInSelect,
			singleBrand
		}
	},
	data () {
		return {
			privacyPolicy: JSON.parse(JSON.stringify(this.privacyPolicyToEdit || {
				privacyPolicyName: '',
				privacyPolicyUrl: null,
				externalVersionRef: '',
				isVisibleInCSPortal: false,
				brandId: defaultBrandInSelect.value
			}))
		}
	},
	computed: {
		isEdit () {
			return !!this.privacyPolicyToEdit
		}
	},
	methods: {
		async submit () {
			if (!this.isEdit) {
				await postPrivacyPolicy(this.privacyPolicy).then(response => {
					this.privacyPolicyId = response.data.newPrivacyPolicyId
					this.$emit('submit')
					this.$emit('get-new-privacy-policy-id', response.data.newPrivacyPolicyId)
				})
				showSnackbar('Privacy Policy has been created successfully')
			} else {
				await putPrivacyPolicy(this.privacyPolicy)
				this.$emit('submit')
				showSnackbar('Privacy Policy has been updated successfully')
			}
			this.$emit('load-privacy-policies')
		}
	}
}
</script>

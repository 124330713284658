<template>
	<div class="cassie-vertical-md">
		<v-card>
			<v-tabs v-model="tab">
				<v-tab>
					Embed Code
				</v-tab>
				<v-tab>
					Legacy Embed Code
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<EmbedCode
						v-if="!dynamicEmbedCode"
						:embed-code="embedCode.embedCode"
					/>
					<EmbedCode
						v-if="dynamicEmbedCode"
						:embed-code="embedCode.embedCode"
						:minified-dynamic-embed-code="embedCode.minifiedDynamicEmbedCode"
					/>
				</v-tab-item>
				<v-tab-item>
					<EmbedCode
						:embed-code="embedCode.legacyEmbedCode"
						is-legacy
					/>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
		<CookieBannerEventsCard v-if="tab === 0" />
		<CookieBannerEventsCardV2 v-if="tab === 1" />
	</div>
</template>

<script>
import EmbedCode from './embed-code.vue'
import CookieBannerEventsCard from '../cookie-banner-installation-events-card.vue'
import CookieBannerEventsCardV2 from '../cookie-banner-installation-events-card-v2.vue'
export default {
	components: {
		EmbedCode,
		CookieBannerEventsCard,
		CookieBannerEventsCardV2
	},
	props: {
		embedCode: {
			type: Object,
			required: true
		},
		dynamicEmbedCode: {
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		tab: null
	})
}
</script>

<template>
	<SectionCard embedded>
		<template #title>
			Additional Functions
		</template>
		<template #subtitle>
			The following functions are supported and can be invoked from scripts on your page.
		</template>
		<template #body>
			<div class="cassie-vertical-md">
				<v-expansion-panels accordion>
					<v-expansion-panel
						v-for="event in V2_ADDITIONAL_FUNCTIONS"
						:key="event.id"
					>
						<v-expansion-panel-header>
							{{ event.name }}
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							{{ event.description }}
							<Prism>
								{{ event.value }}
							</Prism>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</template>
	</SectionCard>
</template>
<script>
import Prism from 'vue-prism-component'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import { V2_ADDITIONAL_FUNCTIONS } from './cookie-banner-events.js'
export default {
	components: {
		SectionCard,
		Prism
	},
	setup () {
		return {
			V2_ADDITIONAL_FUNCTIONS
		}
	}
}
</script>

/* eslint-disable no-useless-escape */
export const EVENTS = [
	{
		id: '1',
		name: 'CassieTemplateFilesLoaded',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieTemplateFilesLoaded\',function() {\n// Your code here...\n});\n</script>'
	},
	{
		id: '2',
		name: 'CassieTemplateInitialized',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieTemplateInitialized\', function(){\n// Your code here...\n});\n</script> '
	},
	{
		id: '3',
		name: 'CassieBannerVisibility',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieBannerVisibility\', function(e){var visible = e.detail; // Returns true or false\n// Your code here...\n});\n</script> '
	},
	{
		id: '4',
		name: 'CassieModalVisibility',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieModalVisibility\', function(e){var visible = e.detail; // Returns true or false\n// Your code here...\n});\n</script> '
	},
	{
		id: '5',
		name: 'CassieSubmitConsent',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieSubmitConsent\', function(e){var preferences = e.detail; // Returns true or false\n// Your code here...\n});\n</script> '
	},
	{
		id: '6',
		name: 'CassieSubmittedConsent',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieSubmittedConsent\', function(e){var preferences = e.detail; // Returns true or false\n// Your code here...\n});\n</script> '
	}
]
export const CONSENT_MANAGEMENT = [
	{
		id: '1',
		name: 'Submit Consent',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\nvar preferences = CassieWidgetLoader.Widget.getPreferences(); // Returns [{channelId, statementId, isOptIn}]\n// Do some work to decide which ones are opt in or out.\n// Pass preferences and specify your source for your own purposes.\nCassieWidgetLoader.Widget.submitConsent(preferences, \'myCustomSource\');\n</script> '
	},
	{
		id: '2',
		name: 'Accept All',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.acceptAll(\'myCustomAcceptAllSource\');\n</script> '
	},
	{
		id: '3',
		name: 'Reject All',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.rejectAll(\'myCustomAcceptAllSource\');\n</script> '
	},
	{
		id: '4',
		name: 'Get Current Consent',
		value:
		// eslint-disable-next-line max-len
			'<script type=\'text/javascript\'>\nvar consent = CassieWidgetLoader.Widget.getCurrentConsent(); // Returns [{channelId, statementId, isOptIn}]\n// If there is no consent, the return is an empty array\n</script> '
	},
	{
		id: '5',
		name: 'Has Consent',
		value:
			'<script type=\'text/javascript\'>\nvar hasConsent = CassieWidgetLoader.Widget.hasConsent(); // Returns true or false\n</script> '
	}
]
export const CUSTOM_TEMPLATES = [
	{
		id: '1',
		name: 'Hide Banner',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.hideBanner();\n</script> '
	},
	{
		id: '2',
		name: 'Show Banner',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.showBanner();\n</script> '
	},
	{
		id: '3',
		name: 'Hide Modal',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.hideModal();\n</script> '
	},
	{
		id: '4',
		name: 'Show Modal',
		value:
			'<script type=\'text/javascript\'>\nCassieWidgetLoader.Widget.showModal();\n</script> '
	}
]
export const GPC_EVENTS = [
	{
		id: '1',
		name: 'CassieGPCBannerDisplayed',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCBannerDisplayed\', function() {\n// Your code here...\n});\n</script>'
	},
	{
		id: '2',
		name: 'CassieGPCAcceptAllClick',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCAcceptAllClick\', function(){\n// Your code here...\n});\n</script> '
	},
	{
		id: '3',
		name: 'CassieGPCAcceptAllExGPCCick',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCAcceptAllExGPCCLick\', function(){\n// Your code here...\n});\n</script> '
	},
	{
		id: '4',
		name: 'CassieGPCBackClick',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCBackClick\', function(){\n// Your code here...\n});\n</script> '
	},
	{
		id: '5',
		name: 'CassieGPCOnClose',
		value:
			'<script type=\'text/javascript\'>\ndocument.addEventListener(\'CassieGPCOnClose\', function(){\n// Your code here...\n});\n</script> '
	}
]
export const V2_ADDITIONAL_FUNCTIONS = [
	{
		id: '1',
		name: 'OnCookieWidgetLoaded',
		description: 'If you wish to override the default behaviour or markup of the cookie widget, you can do this within your page by adding a javascript function called "OnCookieWidgetLoaded". This function, if it exists on the page, will be invoked by the cookie widget as soon as it has finished loading',
		value: '<script type=\'text/javascript\'>\nfunction OnCookieWidgetLoaded() {\n//add javascript here that will fire once the cookie widget has loaded\n}\n</script>'
	},
	{
		id: '2',
		name: 'Syrenis_OpenCookieOptionsModal',
		description: 'Calling this function will open the Cookie Options modal window',
		value: '<script type="text/javascript">\n    Syrenis_OpenCookieOptionsModal();\n</script>'
	},
	{
		id: '3',
		name: 'Syrenis_CloseCookieOptionsModal',
		description: 'Calling this function will close the Cookie Options modal window',
		value: '<script type="text/javascript">\n    Syrenis_CloseCookieOptionsModal();\n</script>'
	},
	{
		id: '4',
		name: 'Syrenis_AcceptAllCookies',
		description: 'Calling this function will accept all cookies and submit these preferences to Cassie',
		value: '<script type="text/javascript">\n    Syrenis_AcceptAllCookies();\n</script>'
	},
	{
		id: '5',
		name: 'Syrenis_RejectAllCookies',
		description: 'Calling this function will reject all cookies and submit these preferences to Cassie',
		value: '<script type="text/javascript">\n    Syrenis_RejectAllCookies();\n</script>'
	}
]

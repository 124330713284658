<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Settings
			</template>
			<template #body>
				<div class="d-flex flex-row cassie-horizontal-sm">
					<div
						v-if="cookieBannerLegacyEmbedCodeVueDisplay && !cdcCookieCollectionSelected"
						class="cassie-input-width-md"
					>
						<div class="text-subtitle-2">
							Legacy Consent Selection Type
						</div>
						<v-radio-group
							v-model="consentSelectionType"
							class="mt-0 pt-0"
							row
							@change="getEmbedCode"
						>
							<v-radio
								label="Slider"
								:value="0"
							/>
							<v-radio
								label="Radio Buttons"
								:value="1"
							/>
						</v-radio-group>
					</div>
					<Toggle
						v-if="!cdcCookieCollectionSelected && enableCassieCloudEmbedCode"
						label="Dynamic Embed Code"
						class="cassie-input-width-md"
						:value.sync="dynamicEmbedCode"
						tooltip-text="This will enable the embed code to use the domain of the hosting page for resource location. Only enable this if your DNS configuration is complete for the hosting domain."
						@update:value="getEmbedCode"
					/>
					<Dropdown
						v-if="cdcCookieCollectionSelected"
						v-model="selectedDomain"
						label="Domain"
						class="cassie-input-width-sm adjusted-dropdown"
						:items="allowedDomains"
						@input="getEmbedCode"
					/>
					<Dropdown
						v-model="selectedLanguage"
						label="Language"
						class="cassie-input-width-sm adjusted-dropdown"
						:items="languageItems"
						@input="getEmbedCode"
					/>
				</div>
			</template>
		</SectionCard>
		<SectionCard v-if="embedCode">
			<template
				v-if="enableCassieCloudEmbedCode"
				#title
			>
				Embed Code: {{ embedCodeTitleLanguageName }}
			</template>
			<template
				v-else
				#title
			>
				<span>
					Embed Code: {{ embedCodeTitleLanguageName }}
					<v-tooltip
						slot="append"
						top
					>
						<template #activator="{ on }">
							<v-icon
								light
								v-on="on"
							>
								mdi-information
							</v-icon>
						</template>
						<span>
							This embed code uses the domain of the page it is hosted on for the resource location, you must configure your DNS for this to work.  Please speak to your Account Manager for more details.
						</span>
					</v-tooltip>
				</span>
			</template>
			<template #body>
				<div v-if="!generatingEmbedCode && enableCassieCloudEmbedCode">
					<div v-if="((!cookieBannerLegacyEmbedCodeVueDisplay || (cdcCookieCollectionSelected && cookieBannerLegacyEmbedCodeVueDisplay)) && !dynamicEmbedCode)">
						<EmbedCode
							:embed-code="embedCode.embedCode"
						/>
					</div>
					<div v-if="((!cookieBannerLegacyEmbedCodeVueDisplay || (cdcCookieCollectionSelected && cookieBannerLegacyEmbedCodeVueDisplay)) && dynamicEmbedCode)">
						<EmbedCode
							:embed-code="embedCode.embedCode"
							:minified-dynamic-embed-code="embedCode.minifiedDynamicEmbedCode"
						/>
					</div>
					<div v-if="cookieBannerLegacyEmbedCodeVueDisplay && embedCode && !cdcCookieCollectionSelected">
						<EmbedCodeTabs
							:embed-code="embedCode"
							:dynamic-embed-code="dynamicEmbedCode"
						/>
					</div>
				</div>
				<div v-if="!generatingEmbedCode && !enableCassieCloudEmbedCode">
					<div v-if="((!cookieBannerLegacyEmbedCodeVueDisplay || (cdcCookieCollectionSelected && cookieBannerLegacyEmbedCodeVueDisplay)) && dynamicEmbedCode)">
						<EmbedCode
							:embed-code="embedCode.embedCode"
							:minified-dynamic-embed-code="embedCode.minifiedDynamicEmbedCode"
						/>
					</div>
					<div v-if="cookieBannerLegacyEmbedCodeVueDisplay && embedCode && !cdcCookieCollectionSelected">
						<EmbedCodeTabs
							:embed-code="embedCode"
							:dynamic-embed-code="dynamicEmbedCode"
						/>
					</div>
				</div>
			</template>
		</SectionCard>
		<CookieBannerEventsCard v-if="!cookieBannerLegacyEmbedCodeVueDisplay" />
	</div>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import CookieBannerEventsCard from './cookie-banner-installation-events-card.vue'
import { getCookieBannerEmbedCode } from '../../../../../../../shared/utils/api/cookies/cookie-banners.js'
import { cookieBannerLegacyEmbedCodeVueDisplay, enableCassieCloudEmbedCode } from '../../../../../../../shared/state/config-keys.js'
import EmbedCode from './embed-code/embed-code.vue'
import EmbedCodeTabs from './embed-code/embed-code-tabs.vue'
export default {
	components: {
		SectionCard,
		Dropdown,
		Toggle,
		EmbedCode,
		EmbedCodeTabs,
		CookieBannerEventsCard
	},
	props: {
		assignedLanguages: Array,
		cookieBannerId: {
			required: true,
			type: Number
		},
		accessKey: {
			required: true,
			type: String
		},
		allowedDomains: {
			required: false,
			type: Array
		},
		cdcCookieCollectionSelected: Boolean
	},
	setup () {
		return { cookieBannerLegacyEmbedCodeVueDisplay, enableCassieCloudEmbedCode }
	},
	data () {
		return {
			embedCode: '',
			languages: [],
			selectedLanguage: 0,
			selectedDomain: '',
			dynamicEmbedCode: false,
			generatingEmbedCode: false,
			haveSelectionsChanged: true,
			consentSelectionType: 0,
			embedCodeTitleLanguageName: ''
		}
	},
	computed: {
		selectedLanguageName () {
			return this.assignedLanguages.find(({ languageID }) => languageID === this.selectedLanguage)?.languageName
		},
		selectedLanguageCode () {
			return this.selectedLanguage === 0 ? '' : this.assignedLanguages.find(({ languageID }) => languageID === this.selectedLanguage)?.languageCode
		},
		languageItems () {
			return this.assignedLanguages.map(({ languageID, languageName }) => ({ value: languageID, text: languageName }))
		}

	},
	async created () {
		if (this.cdcCookieCollectionSelected) {
			this.selectedDomain = this.allowedDomains[0].value
		}
		await this.getEmbedCode()
	},
	methods: {
		async getEmbedCode () {
			this.generatingEmbedCode = true
			// If EnableCassieCloudEmbedCode configkey is false, it is always dynamic embed code
			if (!this.enableCassieCloudEmbedCode) {
				this.dynamicEmbedCode = true
			}
			const { data } = await getCookieBannerEmbedCode(this.cookieBannerId, this.selectedLanguageCode, this.selectedDomain, this.dynamicEmbedCode)
			this.embedCode = data
			if (this.embedCode.legacyEmbedCode) {
				this.embedCode.legacyEmbedCode = this.selectedLanguageCode === null
					? this.embedCode.legacyEmbedCode.replace('[langCode]', '')
					: this.embedCode.legacyEmbedCode.replace('[langCode]', this.selectedLanguageCode)
				this.embedCode.legacyEmbedCode = this.embedCode.legacyEmbedCode.replace('[accessKey]', this.accessKey.toUpperCase())
				this.embedCode.legacyEmbedCode = this.embedCode.legacyEmbedCode.replace('[radio]', this.consentSelectionType === 0 ? '' : '1')
			}
			this.generatingEmbedCode = false
			this.haveSelectionsChanged = false
			this.embedCodeTitleLanguageName = this.selectedLanguageName
		},
		changeSelections () {
			this.haveSelectionsChanged = true
		}
	}
}

</script>

<style lang="scss">
.adjusted-dropdown {
	position: relative;
	top: 5px;
}

</style>
